import EventManagerFactory from "./EventManagerFactory";

const RqEvents = {
    install (Vue, options) {        
        options = options || {};
        const EventManager = EventManagerFactory.create(options.eventPrefix || "rq-app");

        Object.defineProperty(Vue.prototype, '$rqEvents', {
            get () { return EventManager; }
        });
    }
};

export default RqEvents;
